@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MainFont_Regular';
  font-weight: normal;
  src: url('../assets/fonts/HakgyoansimDotbogiR.ttf') format('truetype');
}

:root {
  color-scheme: light only;
}