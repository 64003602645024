.Toastify__toast-body {
  white-space: pre-line;
}

.loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(241, 241, 241, 0.918);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.loading-animation img {
  display: block;
  margin: 0 auto;
}

.wrap {
  position: absolute;
  display: flex;
  left: 24px;
  bottom: 60px;
  width: auto;
  height: 232px;
  margin-left: -76px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  font-size: 12px;
  font-family: 'Malgun Gothic', dotum, '돋움', sans-serif;
  line-height: 1.5;
}
.wrap * {
  padding: 0;
  margin: 0;
}
.wrap .info {
  width: 148px;
  height: 220px;
  border-radius: 12px;
  border-bottom: 2px solid #ccc;
  border-right: 1px solid #ccc;
  overflow: hidden;
  background: #fff;
}
.wrap .info:nth-child(1) {
  border: 0;
  box-shadow: 0px 1px 2px #888;
}
.info .home-title {
  width: 100%;
  position: relative;
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info .title {
  width: 100%;
  position: relative;
  display: block;
  height: 36px;
  padding-top: 8px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info .close {
  display: none;
  position: absolute;
  z-index: 999;
  top: 6px;
  right: 12px;
  font-size: 20px;
  font-weight: 400;
}
.info .close:hover {
  cursor: pointer;
}
.info .body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.info .desc {
  position: relative;
  display: none;
}
.info .img {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0;
  width: 120px;
  height: 172px;
  border: none;
  color: #888;
  overflow: hidden;
}
.info:after {
  content: '';
  position: absolute;
  margin-left: -12px;
  left: 50%;
  bottom: 0;
  width: 22px;
  height: 12px;
  background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png');
}

.wrap-home {
  position: absolute;
  display: flex;
  left: 0;
  bottom: 60px;
  width: auto;
  height: 36px;
  margin-left: -32px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  font-size: 10px;
  font-family: 'Malgun Gothic', dotum, '돋움', sans-serif;
  line-height: 1.5;
}

.wrap-home * {
  padding: 0;
  margin: 0;
}
.wrap-home .info {
  width: 64px;
  height: 32px;
  font-size: 10px;
  border-radius: 12px;
  border-bottom: 2px solid #ccc;
  border-right: 1px solid #ccc;
  overflow: hidden;
  background: #fff;
}

.wrap-home .info:after {
  display: none;
}

.wrap-home .info .title {
  font-size: 10px;
  font-weight: 700;
  padding-top: 0;
}

@media (min-width: 390px) and (max-width: 559px) {
  .wrap {
    width: auto;
    height: 118px;
    font-size: 12px;
  }
  .wrap .info {
    width: 100px;
    height: 108px;
  }
  .info .title {
    height: 28px;
    font-size: 12px;
  }
  .info .close {
    top: 10px;
    right: 12px;
    font-size: 14px;
  }
  .info .img {
    width: 72px;
    height: 68px;
  }

  .wrap-home {
    width: auto;
    left: 4px;
    height: 24px;
    font-size: 10px;
  }
  .wrap-home .info {
    width: 48px;
    height: 20px;
  }
}

@media (min-width: 360px) and (max-width: 389px) {
  .wrap {
    width: auto;
    height: 126px;
    font-size: 12px;
  }
  .wrap .info {
    width: 96px;
    height: 114px;
  }
  .info .title {
    height: 24px;
    font-size: 12px;
  }
  .info .close {
    top: 8px;
    right: 12px;
    font-size: 14px;
  }
  .info .img {
    width: 76px;
    height: 80px;
  }

  .info:after {
    margin-left: -6px;
    left: 50%;
    bottom: 0;
    width: 22px;
    height: 12px;
  }

  .wrap-home {
    width: auto;
    height: 24px;
  }
  .wrap-home .info {
    width: 56px;
    height: 20px;
  }
}

@media (min-width: 276px) and (max-width: 359px) {
  .wrap {
    width: auto;
    height: 110px;
    font-size: 12px;
  }
  .wrap .info {
    width: 96px;
    height: 98px;
  }
  .info .title {
    height: 24px;
    font-size: 10px;
  }
  .info .close {
    top: 6px;
    right: 12px;
    font-size: 12px;
  }
  .info .img {
    width: 68px;
    height: 64px;
  }

  .info:after {
    margin-left: -6px;
    left: 50%;
    bottom: 0;
    width: 22px;
    height: 12px;
  }
  .wrap-home {
    width: auto;
    height: 28px;
  }
  .wrap-home .info {
    width: 52px;
    height: 22px;
  }
}
