.select-btn {
  width: 4rem;
  height: 1.4rem;
  display: flex;
  border: 1px solid #454545;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.select-btn.selected {
  background-color: #454545;
  color: #fff;
  font-weight: 700;
}

input {
  background-color: transparent;
}

input[type='date'] {
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  color: #454545;
}

input[type='date']:valid {
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
  color: #454545;
}

@media (min-width: 276px) and (max-width: 359px) {
  .select-btn {
    width: 3.6rem;
    height: 1.2rem;
    display: flex;
    border: 0.8px solid #454545;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input[type='date'] {
    font-size: 0.7rem;
    font-weight: 400;
    width: 100%;
    color: #454545;
  }

  input[type='date']:valid {
    font-size: 0.7rem;
    font-weight: 600;
    width: 100%;
    color: #454545;
  }
}

@media (min-width: 360px) and (max-width: 389px) {
  .select-btn {
    width: 4.4rem;
    height: 1.4rem;
    display: flex;
    border: 1px solid #454545;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input[type='date'] {
    font-size: 0.84rem;
    font-weight: 400;
    width: 100%;
    color: #454545;
  }

  input[type='date']:valid {
    font-size: 0.84rem;
    font-weight: 600;
    width: 100%;
    color: #454545;
  }
}

@media (min-width: 390px) and (max-width: 559px) {
  .select-btn {
    width: 4.8rem;
    height: 1.6rem;
    display: flex;
    border: 1px solid #454545;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input[type='date'] {
    font-size: 0.9rem;
    font-weight: 400;
    width: 100%;
    color: #454545;
  }

  input[type='date']:valid {
    font-size: 0.9rem;
    font-weight: 600;
    width: 100%;
    color: #454545;
  }
}

@media (min-width: 560px) and (max-width: 667px) {
  .select-btn {
    width: 4.8rem;
    height: 1.8rem;
    display: flex;
    border: 1.2px solid #454545;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input[type='date'] {
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    color: #454545;
  }

  input[type='date']:valid {
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    color: #454545;
  }
}

@media (min-width: 668px) and (max-width: 1023px) {
  .select-btn {
    width: 5.8rem;
    height: 2.2rem;
    display: flex;
    border: 1.4px solid #454545;
    border-radius: 24px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input[type='date'] {
    font-size: 1.2rem;
    font-weight: 400;
    width: 100%;
    color: #454545;
  }

  input[type='date']:valid {
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    color: #454545;
  }
}

@media (min-width: 1024px) {
  .select-btn {
    width: 7.2rem;
    height: 2.4rem;
    display: flex;
    border: 1.6px solid #454545;
    border-radius: 24px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  input[type='date'] {
    font-size: 1.4rem;
    font-weight: 400;
    width: 100%;
    color: #454545;
  }

  input[type='date']:valid {
    font-size: 1.4rem;
    font-weight: 600;
    width: 100%;
    color: #454545;
  }
}
