/* .scrollbar::-webkit-scrollbar {
  display: none;
} */

.scrollbar::-webkit-scrollbar-track {
  background-color: #454545;
}

.select-profile.selected {
  border: 2.4px solid #454545;
}

.select-group.selected {
  font-weight: 700;
}
